body {
    font-family: "Oscine",sans-serif;
    font-size: 16px;
    color: #606060;
}
h1, h2, h3, h4 {
    text-transform: uppercase;
    color: #1D2021;
    letter-spacing: 3px;
    line-height: 1.2;
}
.table-wrapper { /* responsive tables */
    overflow-x: auto;
}
.table-wrapper.active:before,
.table-wrapper.active:after { /* set in js*/
    content: "< scroll >";
    display: block;
    width: 100%;
    color: #000;
    font-weight: bold;
    text-align: right;
}
table {
    width: 100% !important;
    /* table-layout: fixed; */
    box-sizing: border-box;
    border-collapse: collapse;
    border: 1px solid #F3EFEB;
}
td {
    padding-left: 10px;
    box-sizing: border-box;
}

.clear-float {
    clear:both;
}

/* text colours */
.highlight {
    color: #bbbbbb; /* overridden by the themes in main.css */
}

/* img */
img.display-left {
    float:left;
    margin-right:20px;
    margin-bottom:20px;
}
img.display-right {
    float:right;
    margin-left:20px;
    margin-bottom:20px;
}
.greytocolor {
    filter: grayscale(1);
    transition: filter 0.3s;
    max-width: 200px;
    margin: 1%;
}
.greytocolor:hover{
    filter: grayscale(0);
}

/* blocks */
.hofwrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
}
.hofoption {
    position: relative;
    display: inline-block;
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px 20px;
    width: 47%;
    min-height: 160px;
    color: #1D2021;
    border: 2px solid #F3EFEB;
    box-sizing: border-box;
    margin: 1%;
}
.hofoption a {
    color: inherit;
    text-decoration: none;
}
.hofoption:hover {
    background-color: #F3EFEB
}

.col3 {
    width: 240px;
    display: inline-block;
    vertical-align: top;
    margin: 10px;
}
.col2 {
    width: 49%;
    display: inline-block;
    vertical-align: middle;
}

/* buttons */
.button,
.button-black {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    color: #1D2021;
    background-color: #C5C6D1;
    font-size: 18px;
    padding: 10px 6%;
    min-width: 300px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}
.button:hover,
.button-black {
    color: #FFF;
    background-color: #1D2021;
}
.button-black:hover {
    color: #1D2021;
    background-color: #C5C6D1;
}

/** awards style **/
.awardstitle {
    border-bottom: 1px dashed #606060;
    height: 50px;
    position: relative;
    line-height: 50px;
    padding: 10px 0;
    margin: 0;
    font-size: 18px;
}
.awardstitle:after {
    content:"";
    display: table;
    width: 100%;
    height: 1px;
    clear: both;
}
.awardstitle .button,
.awardstitle .button-black{
    float: right;
}

/** feature links **/
.feat-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
}
.corpbtn {
    display: inline-block;
    vertical-align: middle;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: 31%;
    padding: 6% 3%;
    min-height: 160px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: normal;
    text-decoration: none;
    color: #1D2021;
    border: 5px solid #F3EFEB;
    box-sizing: border-box;
    margin: 0.5%;
    transition: background-color 0.3s ease-in-out;
}
.t-page .page-content .corpbtn a {
    color: #1D2021;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}
.corpbtn:hover { /* text colour done in main */
    background-color: #F3EFEB;
}


@media only screen and (max-width: 760px){
    .col2 {
        width: 100%;
        margin: 0;
    }
    .hofwrapper,
    .feat-wrapper {
        justify-content: center;
    }
    .corpbtn {
        width: 48%;
        min-height: 100px;
    }
}
@media only screen and (max-width:600px){
    .awardstitle .button,
    .awardstitle .button-black {
        float: none;
    }
    .hofwrapper,
    .feat-wrapper {
        display: block;
        text-align: center;
    }
    .hofoption,
    .corpbtn {
        width: 100%;
        height: auto;
        margin: 1px 0;
    }
}
@media only screen and (max-width: 450px){
    .no-js .table-wrapper:before,
    .no-js .table-wrapper:after {
        content: "< scroll >";
        display: block;
        width: 100%;
        color: #000;
        font-weight: bold;
        text-align: right;
    }
    .button,
    .button-black {
        min-width: 0px;
        width: auto;
    }
}

@media only screen and (max-width: 400px){
    .button,
    .button-black {
        padding: 10px 2%;
        width: 100%;
    }
}